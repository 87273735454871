import React, { useState, useEffect } from 'react'
import { postPressPermitMessage } from '../../utils'
import { Formik, Form, Field } from 'formik'
import { CustomSelect, Confirmation } from '.'
import { BioButton, BioCheckbox, BioTextArea, BioTextInput } from '../shared'
import * as Yup from 'yup'
import { getLocale, getLocaleUrl } from '../../utils/localeUtils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { BioDatePicker } from '../shared/atoms/BioDatePicker'

export const PressPermitForm = ({ formData, sendMessage, errorMessage }) => {
  const locale = getLocale()
  const createInitialValues = formData => {
    let initial = {}
    for (const key in formData) {
      initial[key] = formData[key].initialValue || ''
    }
    initial.phone = false
    initial.email = false
    initial.airdate = ''
    initial.confirmAge = false
    initial.locale = locale
    return initial
  }

  const pressPermitSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Ungültiges Email-Format')
      .required('Pflichtfeld'),
    phoneNumber: Yup.string().required('Pflichtfeld'),
    media: Yup.string().required('Pflichtfeld'),
    liaison: Yup.string().required('Pflichtfeld'),
    city: Yup.string().required('Pflichtfeld'),
    topic: Yup.string().required('Pflichtfeld'),
    availability: Yup.string().required('Pflichtfeld'),
    preferredLocation: Yup.string().required('Pflichtfeld'),
    privacy: Yup.boolean()
      .oneOf([true], 'Bitte stimme den Datenschutzbestimmungen zu')
      .required('Pflichtfeld'),
    confirmAge: Yup.boolean()
  })

  // let market = getLocaleMarket()

  const [submitted, setSubmitted] = useState(false)
  const [submitMessage, setSubmitMessage] = useState('')
  const [isSending, setIsSending] = useState(true)

  const [initialValues, setInitialValues] = useState(
    createInitialValues(formData)
  )

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={pressPermitSchema}
        onSubmit={async (values, { setSubmitting }) => {
          let formData = new FormData()
          setIsSending(true)
          Object.keys(values).forEach(key => {
            formData.append(key, values[key])
          })

          try {
            const res = await postPressPermitMessage(formData)
            res.response.status == 200
              ? setSubmitMessage(sendMessage)
              : setSubmitMessage(errorMessage)
          } catch (error) {
            setSubmitMessage(errorMessage)
          }

          setSubmitting(false)
          setIsSending(false)
          setSubmitted(true)
        }}>
        {({ values, errors, touched }) => {
          const touchedErrors = Object.entries(errors).find(
            ([k, v]) => touched[k]
          )

          const button = { buttonCta: 'Absenden' }
          return (
            <Form id="pressPermit-form">
              <section className="pb-12">
                <BioTextInput {...formData.media} />
                <BioTextInput {...formData.editorial} />
                <BioTextInput {...formData.liaison} />
                <BioTextInput {...formData.city} />
                <BioTextInput {...formData.emailAddress} />
                <BioTextInput {...formData.phoneNumber} />
                <BioTextInput {...formData.topic} />
                <BioDatePicker name="airdate" {...formData.airdate} />

                <BioTextArea {...formData.preferredLocation} />
                <BioTextArea {...formData.availability} />
                <BioCheckbox
                  type="checkbox"
                  {...formData.confirmAge}
                  label={'Ja, ich habe das 16. Lebensjahr vollendet'}
                  isForm={true}
                  name={'confirmAge'}
                />
                <Field type="hidden" name={locale} {...formData.locale} />
                <BioCheckbox
                  type="checkbox"
                  {...formData.privacy}
                  isForm={true}
                />
              </section>

              <div
                className="flex justify-center py-6"
                data-cy={'pressPermit-submit'}>
                <BioButton type="submit" button={button} />
              </div>

              {touchedErrors?.length > 0 && errors && (
                <span className="text-sm text-orange">
                  Es wurden fehlende oder falsche Daten angegeben. Bitte
                  überprüfe Deine Eingaben.
                </span>
              )}

              <Confirmation
                isSending={isSending}
                isSubmitted={submitted}
                setSubmitted={setSubmitted}
                headline={submitMessage}
                navigate={getLocaleUrl(locale, '/presse')}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
